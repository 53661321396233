import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { createValidateStatus, setAuthHeaders } from '@/utils/servicesUtils';
import { BASE_SYSTEM_USER, SERVER_URL, SUPPORT_SERVICE, WX_GZH_APPID } from '@/config/constants';
import { Result } from '@/types';

// 短信类型，0：验证码登录；1：修改密码；2：找回密码；3：绑定手机号
export type MessageAction = 0 | 1 | 2 | 3;

export interface SendMessageParams {
  mobile: string;
  action: MessageAction;
}

export interface WxUser {
  groupId: number;
  headImgUrl: string;
  language: string;
  nickname: string;
  openId: string;
  privileges: null;
  qrScene: string;
  qrSceneStr: string;
  remark: string;
  subscribe: boolean;
  subscribeScene: string;
  subscribeTime: string;
  tagIds: [];
  unionId: string;
}

const base = createApi({
  tagTypes: [
    'teachingBuildingList', // 教学楼列表
    'scheduleRuleList', // 规则列表
    'scheduleSheetData', // 课时任课数据
    'adjustSchedule',
    'adjustAddSchedule',
    'adjustRemoveSchedule',
    'autoArrange',
    'clearSchedule',
    'lock',
    'scheduleList', // 方案列表
    'scheduleInfo', // 方案详情
    'gradeClassList', // 年级班级列表
    'gradeBySchemaId', // 当前方案的年级
    'uniqCourseNames', // 课程名列表
    'timeTable', // 设置条件课表
    'timeTable', // 设置条件课表
    'remainCourseClassCount', // 手动调整 - 课程班级剩余的课时
    'getOddAndDoubleBindInfoByGrade',
    'AdjustExchangeList',
    'overlapped',
  ],
  baseQuery: fetchBaseQuery({
    baseUrl: SERVER_URL,
    prepareHeaders: (headers) => {
      // setAuthHeaders(headers);
      return headers;
    },
    fetchFn: (input, init) => {
      return fetch(input, init).catch((error) => {
        // 网络故障或者请求被阻止时触发
        console.error('Request Error: 网络故障或者请求被阻止!', error);
        throw error;
      });
    },
    validateStatus: createValidateStatus(),
  }),
  endpoints: ({ mutation }) => ({
    // 创建公众号二维码
    createQrcode: mutation<Result<{ qrCodeUrl: string; scene: string }>,
      { scene: string }>({
      query: (params) => ({
        url: `${BASE_SYSTEM_USER}/systemUser/wx/without/l/${WX_GZH_APPID}/qrcode`,
        method: 'GET',
        params: {
          scene: params.scene,
        },
      }),
    }),

    // 发送验证码
    sendMessage: mutation<Result, SendMessageParams>({
      query: (params) => ({
        url: `${SUPPORT_SERVICE}/support/sms/token`,
        method: 'POST',
        body: params,
      }),
    }),

    // 生成微信js-sdj签名信息
    createWxSdkSignInfo: mutation<Result<{
      appId: string
      nonceStr: string
      signature: string
      timestamp: string
      url: string
    }>, { appId: string, url: string }>({
      query: (params) => ({
        url: `${BASE_SYSTEM_USER}/systemUser/wx/without/l/${params.appId}/config`,
        method: 'GET',
        params: {
          url: params.url,
        },
      }),
    }),

    // 通过微信授权获取用户信息
    getUserInfoByWxCode: mutation<Result<WxUser>, { appId: string, code: string }>({
      query: (params) => ({
        url: `${BASE_SYSTEM_USER}/systemUser/wx/without/l/${params.appId}/userInfo`,
        method: 'GET',
        params: {
          code: params.code,
        },
      }),
    }),

    // 检查用户是否注册 1已注册，2未注册
    checkMobileHasRegistered: mutation<Result<{ userCheckStatus: 1 | 2 }>, { mobile: string }>({
      query: (params) => ({
        url: `${BASE_SYSTEM_USER}/systemUser/without/l/mobile/exist`,
        method: 'GET',
        params: params,
      }),
    }),

    // 邀请注册
    registerByInvited: mutation<Result, {
      avatarUrl: string;
      code: string;
      inviterId: string;
      nickName: string;
      phoneNumber: string;
      unionid: string;
      inviterType: string; // 1快排课，2快成绩
    }>({
      query: (params) => ({
        url: `${BASE_SYSTEM_USER}/systemUser/wx/without/l/${WX_GZH_APPID}/invite/phone`,
        method: 'GET',
        params: {
          ...params,
        },
      }),
    }),

    // 用户手机注册
    registerByPhone: mutation<Result, {
      'mobile': string,
      'captcha': string,
      // 注册来源：1.快排课web 2.快成绩web 3.快成绩小程序 4.快课表小程序,5.倍思家长小程序,6 welink，7.桂教通 8.企业微信
      // 9快排课手机注册，10快成绩手机注册 11.快选课手机注册 12.快选课web 13快监考
      source: 9 | 10 | 11 | 13,
      from?: string,
      refer?: string,
    }>({
      query: (params) => ({
        url: `${BASE_SYSTEM_USER}/systemUser/login/captcha`,
        method: 'POST',
        body: params,
      }),
    }),

  }),
});

export const {
  useCreateQrcodeMutation,
  useSendMessageMutation,
  useCreateWxSdkSignInfoMutation,
  useGetUserInfoByWxCodeMutation,
  useCheckMobileHasRegisteredMutation,
  useRegisterByInvitedMutation,
  useRegisterByPhoneMutation,
} = base;

export default base;
